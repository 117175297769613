@import "src/variables";

.download-app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;

    button {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
    }

    svg {
        font-size: 1.3em;
        color: white;
    }
}
@import "../../_variables.scss";

.navbar-container {
    height: $navbar-height;
    width: 100%;
    background: $primary;
    position: fixed;
    overflow: hidden;
    top: 0;

    .main {
        display: grid;
        grid-template-columns: auto 40px;
        padding: 20px;

        .logo {
            // filter: brightness(0) saturate(100%) invert(15%) sepia(11%) saturate(398%) hue-rotate(32deg) brightness(102%) contrast(84%);
        }

        .settings {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            svg {
                color: $secondary;
                font-size: 1.4em;
            }
        }
    }
}
@import "../../variables";

$edge-radius: 10px;

.card-item-container {
    @include cast-shadow(10px);
    display: grid;
    grid-template-columns: 100px auto;
    width: 100%;
    height: 90px;
    margin: 20px 0;
    border-radius: $edge-radius;

    img {
        width: 100%;
        height: 90px;
        object-fit: cover;
        border-radius: $edge-radius 0 0 $edge-radius;
    }

    .content {
        margin: 5px 10px;
        color: $text;
        
        p, a {
            margin-top: 2px;
            font-size: 0.8em;
            color: $text;
        }

        .adress {
            margin: 5px 0;
            font-weight: bold;

            svg {
                color: $primary;
            }
        }
    }
}
@import "../../variables";

.navbar-items-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background: $secondary;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
}
@import "../../variables";

$location-button-size: 50px;
$button-space: calc($location-button-size + 10px);

.about-information-container {
    margin: 40px 20px;

    .about-name {
        color: $text;
        display: grid;
        grid-template-columns: auto $button-space;
        width: 100%;
        height: $button-space;
        vertical-align: middle;

        .name {
            display: flex;
            align-items: center;

            h2 {
                font-weight: bold;
            }
        }

        a {
            margin-top: 5px;
            margin-left: 5px;
            width: $location-button-size;
            height: $location-button-size;
            background: $primary;
            border-radius: 50%;

            svg {
                width: calc($location-button-size - 15px);
                height: calc($location-button-size - 15px);
                color: white;
                margin-left: 6px;
                margin-top: 9px;
            }
        }
    }

    .description {
        width: 100%;
        height: fit-content;
        margin: 30px 0;
        color: $text-light;
    }

    .contact-links {
        display: grid;
        grid-template-columns: 1fr;

        p, a {
            color: $text-light;
            margin: 8px 0;
            vertical-align: middle;
        }

        svg {
            font-size: 1.2em;
            margin-right: 5px;
        }

        a {
            color: $primary;
        }
    }
}
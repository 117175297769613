@import "../../variables";

.navbar-item-container {
    width: 100%;
    text-align: center;
    color: $text;

    p {
        font-size: 0.7em;
        font-weight: bold;
    }

    svg {
        font-size: 1.5em;
        margin: 4px;
    }
}

.active-nav-item {
    color: $primary;
}
@import "../../variables";

$image-edge: 10px;

.news-card-container {
    margin-top: 60px;
    color: $text;

    img {
        width: 100%;
        aspect-ratio: 16 / 10;
        object-fit: cover;
        border-radius: $image-edge;
        @include cast-shadow(10px, 0.2);
    }

    p {
        color: $text-light;
    }

    h2, p {
        margin: 10px 0;
    }
}